import React from 'react';
import Game from './components/Game';
import './App.css'; // Make sure you have this CSS file for basic styling

function App() {
  return (
    <div className="App">
      <Game />
    </div>
  );
}

export default App;
