import React, { useEffect, useRef, useState } from 'react';
import Matter from 'matter-js';

const createStar = (world, render) => {
    console.log("Creating star");  // This should log in the console when a star is created
    const x = Math.random() * render.options.width;
    const y = 0; // Start off-screen
  
    // Original sprite dimensions
    const originalWidth = 1024;
    const originalHeight = 1024;
  
    // Desired display dimensions
    const displayWidth = 20;
    const displayHeight = 20;
  
    // Calculate scale
    const xScale = displayWidth / originalWidth;
    const yScale = displayHeight / originalHeight;
  
    const star = Matter.Bodies.circle(x, y, displayWidth / 2, {
        render: {
            sprite: {
                texture: '/assets/star.png',
                xScale: xScale,
                yScale: yScale,
            },
        },
        // isSensor: true, // Uncomment this if you want the star to not physically affect other bodies
    });
  
    Matter.World.add(world, star);
  
    // Apply a continual force to the star
    Matter.Events.on(render.engine, 'beforeUpdate', function() {
        const forceY = 0.00005; // Adjust as needed for your game's feel
        Matter.Body.applyForce(star, { x: star.position.x, y: star.position.y }, { x: 0, y: forceY });
    });
  
    return star;
};
  
const Game = () => {
    const gameContainerRef = useRef(null);
    const engineRef = useRef(Matter.Engine.create({ gravity: { y: 0 } })); 
    const rocketRef = useRef(null);
    const scoreRef = useRef(0); // Use ref for score
    const starIntervalDurationRef = useRef(2000); // Use ref for starIntervalDuration
    const [distance, setDistance] = useState(0); // Distance between the rocket and the bottom.
    const [displayScore, setDisplayScore] = useState(0);

    useEffect(() => {
        const engine = engineRef.current;
        const render = Matter.Render.create({
            element: gameContainerRef.current,
            engine: engine,
            options: {
                width: window.innerWidth,
                height: window.innerHeight,
                wireframes: false,
                background: 'transparent',
            },
        });
  
        // Create an initial star
        const stars = [];
  
        let starInterval = setInterval(() => {
            const newStar = createStar(engine.world, render);
            stars.push(newStar); // Add the new star to the tracking array
        }, starIntervalDurationRef.current); 
  
        // Regularly check for off-screen stars and remove them
        const cleanupInterval = setInterval(() => {
            stars.forEach((star, index) => {
                if (star.position.y > render.options.height) { // Check if the star is off-screen
                    Matter.World.remove(engine.world, star); // Remove the star from the world
                    stars.splice(index, 1); // Remove the star from the tracking array
                }
            });
        }, 100); // Check every 100 milliseconds
  
        // Create and add the rocket to the world
        // Desired display dimensions
        const originalWidth = 1024;
        const originalHeight = 1024;
        const displayWidth = 80;
        const displayHeight = 80;
  
        // Calculate scale
        const xScale = displayWidth / originalWidth;
        const yScale = displayHeight / originalHeight;
  
        // Create the physics body with the display dimensions
        const rocket = Matter.Bodies.rectangle(
            render.options.width / 2, 
            render.options.height / 2, 
            displayWidth, // width of the rocket body
            displayHeight, // height of the rocket body
            {
                inertia: Infinity,
                render: {
                    sprite: {
                        texture: '/assets/rocket.png',
                        xScale: xScale,
                        yScale: yScale,
                        xOffset: 0.5,
                        yOffset: 0.5
                    },
                },
            }
        );
  
        rocketRef.current = rocket;
        Matter.World.add(engine.world, rocket);
  
        Matter.Engine.run(engine);
        Matter.Render.run(render);
  
        // Set up keyboard controls
        const handleKeyDown = (e) => {
            const rocket = rocketRef.current;
            if (!rocket) return;
            
            const force = 4;
            if (e.key === 'ArrowRight') {
                Matter.Body.setVelocity(rocket, { x: force, y: 0 });
            } else if (e.key === 'ArrowLeft') {
                Matter.Body.setVelocity(rocket, { x: -force, y: 0 });
            }
        };
  
        const handleTouchStart = (e) => {
            const touchX = e.touches[0].clientX; // Get the x position of the touch
            const rocket = rocketRef.current;
            if (!rocket) return;
  
            // Determine if the touch is on the left or right side of the screen
            if (touchX < window.innerWidth / 2) {
                // Touch is on the left side, move rocket left
                Matter.Body.setVelocity(rocket, { x: -4, y: 0 });
            } else {
                // Touch is on the right side, move rocket right
                Matter.Body.setVelocity(rocket, { x: 4, y: 0 });
            }
        };
  
        const handleTouchEnd = (e) => {
            // Stop the rocket when the touch ends
            const rocket = rocketRef.current;
            if (rocket) {
                Matter.Body.setVelocity(rocket, { x: 0, y: 0 });
            }
        };
  
        // Add touch event listeners
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchend', handleTouchEnd);
  
        window.addEventListener('keydown', handleKeyDown);
  
        // Timer to increase score every second
        const timer = setInterval(() => {  
            starIntervalDurationRef.current = Math.max(10, starIntervalDurationRef.current * 0.92);
            updateStarInterval();
        }, 1000);
  
        // Update the star creation interval
        const updateStarInterval = () => {
            console.log("Updating star creation interval to:", starIntervalDurationRef.current); // This should log the updated interval

            clearInterval(starInterval);
            starInterval = setInterval(() => {
                const newStar = createStar(engine.world, render);
                stars.push(newStar);
            }, starIntervalDurationRef.current);
        };
  
        // Watch for changes in starIntervalDuration and update the interval
        const intervalWatcher = setInterval(updateStarInterval, 1000); // Check every second
  
        // Regularly check rocket's position and calculate the distance
        const positionCheck = setInterval(() => {
            const rocket = rocketRef.current;
            if (rocket) {
                const distanceToBottom = render.options.height - rocket.position.y;
                setDistance(distanceToBottom.toFixed(2)); // Update distance
  
                if (distanceToBottom <= 0) {
                    // Rocket has reached the bottom of the screen
                    alert(`Game over! Your survival time: ${scoreRef.current} seconds`);
                    clearInterval(timer); // Stop the timer
                    clearInterval(starInterval); // Stop creating stars
                    clearInterval(cleanupInterval); // Stop the cleanup
                    clearInterval(positionCheck); // Stop checking position
                    // Reset the game or navigate to a game-over screen
                }
            }
        }, 100);
  
        return () => {
            clearInterval(timer);
            // clearInterval(starInterval);
            clearInterval(cleanupInterval);
            clearInterval(positionCheck);
            clearInterval(intervalWatcher);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
            Matter.Render.stop(render);
            Matter.World.clear(engine.world);
            Matter.Engine.clear(engine);
            render.canvas.remove();
        };
    }, []);
  
    useEffect(() => {
      const timer = setInterval(() => {
          scoreRef.current += 1;
          setDisplayScore(scoreRef.current); // Or force an update
      }, 1000);
  
      return () => clearInterval(timer); // Clear interval on component unmount or when dependencies change
  }, []); // Empty dependency array means this effect runs once on mount

    return (
      <div ref={gameContainerRef} className="gameContainer">
        <div className="score">Survival Time: {displayScore} seconds</div>
        <div className="distance">Distance to Bottom: {distance} pixels</div>
      </div>
    );
    
  };
  
  export default Game;
